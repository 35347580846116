
import { FormValidations } from "@/mixins/form-validations";
import {
  ProductDetail as ProductDetailInterface,
  Detail,
} from "@/models/detail.interface";
import { Term } from "@/models/term.interface";
import Component, { mixins } from "vue-class-component";
import { Prop, Vue, Watch } from "vue-property-decorator";
import TermForm from "@/components/Layout/Backoffice/TermForm.vue";
import ColorPicker from "@/components/Input/ColorPicker.vue";
import { VueEditor } from "vue2-editor";
import Terms from "@/views/Staff/Terms.vue";
import ProductDetailFile from "@/components/Layout/Common/ProductDetailFile.vue";
import { EditorImages } from "@/mixins/editor-images";
import { FileCheck } from "@/mixins/file-check";
import { StyleCheck } from "@/mixins/style-check";

@Component({
  components: {
    TermForm,
    ColorPicker,
    VueEditor,
    ProductDetailFile,
  },
})
export default class DetailInput extends mixins(
  FormValidations,
  EditorImages,
  FileCheck,
  StyleCheck
) {
  @Prop() product_detail!: ProductDetailInterface;
  @Prop({ default: 0 }) number!: number;
  @Prop() details!: Detail[];
  @Prop() terms!: Term[];
  @Prop({ default: false }) readonly!: boolean;
  detail: ProductDetailInterface = {
    value: undefined,
    terms: [],
    files: [],
    detail: undefined,
  };

  $refs!: {
    menu: any;
  };

  termDialog = false;
  detailDialog = false;
  activePicker: null | string = null;
  menu = false;
  showErrorDescription = false;

  private mounted() {
    if (this.product_detail.detail) {
      Vue.set(this, "detail", this.product_detail);
    }
  }

  @Watch("product_detail", { deep: true })
  private watchProductDetail() {
    if (this.product_detail.detail) {
      Vue.set(this, "detail", this.product_detail);
    }
  }

  private deleteDetail() {
    this.$emit("deleteDetail", this.number);
  }

  private closeTermDialog(term: Term) {
    this.termDialog = false;
    if (term.id) {
      this.detail?.terms?.push({ term: term });
      this.$emit("loadTerms");
    }
  }

  private closeDetailDialog(detail: Detail) {
    this.detailDialog = false;
    if (detail.id && this.detail) {
      this.detail.detail = detail;
      this.$emit("loadDetails");
    }
  }

  private customFilter(item: Detail, queryText) {
    const textOne: string = item.name as string;
    const textTwo: string = this.$tc(
      "Details.Type." + (item.type as string).toLowerCase()
    ).toLowerCase();
    const text = textOne + " (" + textTwo + ")";
    const searchText = queryText.toLowerCase();

    return text.toLowerCase().indexOf(searchText) > -1;
  }

  private customTermFilter(item: Term, queryText) {
    const textOne: string = item.max_num
      ? item.num + "-" + item.max_num
      : String(item.num);
    const textTwo: string = this.$tc(
      "Terms.Type." + (item.type as string).toLowerCase()
    ).toLowerCase();
    const text = textOne + " " + textTwo;
    const searchText = queryText.toLowerCase();

    return text.toLowerCase().indexOf(searchText) > -1;
  }

  @Watch("menu")
  private watchMenu(val) {
    val && setTimeout(() => (this.activePicker = "YEAR"));
  }

  private save(date) {
    this.$refs.menu.save(date);
  }

  private addTerm() {
    this.detail?.terms?.push({ term: undefined });
    this.changeDetail();
  }

  private deleteTerm(key: number) {
    this.detail?.terms?.splice(key, 1);
    this.changeDetail();
  }

  private changeTerm(term: Term, key: number) {
    Vue.set(this.detail?.terms as Terms[], key, term);
  }

  @Watch("detail", { deep: true })
  changeDetail() {
    this.$emit("change", this.detail);
  }

  changeFiles(files: File[]) {
    if (this.detail) Vue.set(this.detail, "files", files);
    this.changeDetail();

    this.checkFilesType(files, this.fileInputsAccept.global, () => {
      Vue.set(this.detail, "files", []);
      this.changeDetail();
    });
  }

  @Watch("detail.value")
  checkDescription(): void {
    if (this.detail) {
      if (
        this.detail.value ||
        (this.detail.detail &&
          this.detail.detail.type !== this.$constants.DETAIL_TYPES.TEXT)
      ) {
        this.showErrorDescription = false;
      } else {
        this.showErrorDescription = true;
      }
    }
  }

  @Watch("detail.detail", { deep: true })
  watchDetail(detail: Detail, previous: Detail): void {
    if (previous && previous.id) {
      this.detail.id = 0;
      this.detail.value = undefined;
      this.detail.terms = [];
      this.detail.files = [];
    }
    if (
      detail.type == this.$constants.DETAIL_TYPES.COLOR &&
      !this.detail.value
    ) {
      this.detail.value = "#1976D2FF";
    }
    if (
      detail.type == this.$constants.DETAIL_TYPES.TERM &&
      this.detail.terms.length == 0
    ) {
      this.detail.terms.push({ term: undefined });
    }
  }
}
