
import { FormValidations } from "@/mixins/form-validations";
import { Deadline } from "@/models/deadline.interface";
import { Term } from "@/models/term.interface";
import Component, { mixins } from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";
import { StyleCheck } from "@/mixins/style-check";

@Component({})
export default class DeadlineInput extends mixins(FormValidations, StyleCheck) {
  @Prop() deadline!: Deadline;
  @Prop({ default: 0 }) number!: number;
  @Prop({ default: false }) showDelete!: boolean;
  @Prop({ default: false }) showErrorPercentage!: boolean;
  @Prop({ default: 0 }) roi!: number;
  @Prop() terms!: Term[];
  @Prop({ default: false }) readonly!: boolean;

  deadlinePrice = 0;

  private mounted() {
    this.deadlinePercentageHandler();
  }

  private addDeadline() {
    this.$emit("addDeadline", this.number);
  }

  private deleteDeadline() {
    this.$emit("deleteDeadline", this.number);
  }

  @Watch("roi")
  @Watch("deadline.percentage")
  private deadlinePercentageHandler() {
    this.deadline.percentage = parseFloat(
      Number(this.deadline.percentage).toFixed(2)
    );
    this.deadlinePrice = Number(
      (Number(this.roi) * Number(this.deadline.percentage)) / 100
    );
  }

  @Watch("deadline.price")
  private deadlinePriceHandler() {
    let price = this.deadlinePrice ? Number(this.deadlinePrice) : 0;
    this.deadline.percentage = (100 * price) / this.roi;
  }

  private customFilter(item: Term, queryText) {
    const textOne: string = item.max_num
      ? item.num + "-" + item.max_num
      : String(item.num);
    const textTwo: string = this.$tc(
      "Terms.Type." + (item.type as string).toLowerCase()
    ).toLowerCase();
    const text = textOne + " " + textTwo;
    const searchText = queryText.toLowerCase();

    return text.toLowerCase().indexOf(searchText) > -1;
  }
}
